<template>
  <div>
    <v-dialog v-model="$store.state.dialog" persistent :overlay="false" max-width="1000px" transition="dialog-transition">
      <v-card flat>
        <v-card-title>
          <span class="headline">
            {{ $store.state.dialogType === "add" ? "اضافة" : "تعديل" }}
            منتج
          </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form v-model="valid" ref="form">
            <v-row v-if="!$store.state.loading">
              <v-col cols="12" md="6">
                <v-text-field outlined label="العنوان" name="title" :rules="[$rules.required()]"
                  v-model="inputs.data.title" placeholder="قم بإدخال العنوان"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group row label="الحالة:" v-model="inputs.data.status">
                  <v-radio label="مفعل" value="enabled"></v-radio>
                  <v-radio label="غير مفعل" value="disabled"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field outlined label="السعر" name="title" v-model="inputs.data.price" :rules="[$rules.required()]"
                  placeholder="قم بإدخال السعر"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="ERP Code" name="erp_code" v-model="inputs.data.erp_code"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field outlined label="الخصم" name="title" v-model="inputs.data.discount"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select outlined label="نوع الخصم" name="title" :items="discountTypes"
                  v-model="inputs.data.discount_type"></v-select>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  label="العملة"
                  name="title"
                  
                  v-model="inputs.data.currency"
                  placeholder="د.ع"
                ></v-text-field> -->
              <!-- </v-col> -->
              <v-col cols="6">
                <v-autocomplete :items="categories" label="المجموعة" v-model="inputs.data.category" item-text="title"
                  item-value="id" :rules="[$rules.required()]" outlined></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete :items="designs" label="التصميم" v-model="inputs.data.design" item-text="title"
                  item-value="id" :rules="[$rules.required()]" outlined></v-autocomplete>
              </v-col>


              <v-col cols="12" md="12">
                <div class="d-flex justify-space-between">
                  <div>
                    <h3>الاضافات</h3>
                    <p>مثل: الاحجام، الصلصات ، الخ...</p>
                  </div>
                  <v-btn color="primary" @click="addAdditons" outlined>
                    <v-icon>mdi-plus</v-icon>
                    اضافة
                  </v-btn>
                </div>
                <v-col cols="12" v-for="(additon, i) in additons" :key="'add' + i">
                  <v-row>
                    <template>
                      <v-col cols="5">
                        <v-text-field outlined label="الاضافة" name="title" v-model="additon.text" dense>
                        </v-text-field>
                      </v-col>

                      <v-col cols="2">
                        <v-switch v-model="additon.priced" label="مسعرة ؟"></v-switch>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field outlined label="السعر" class="mx-1" v-if="additon.priced" dense name="title" number
                          v-model="additon.price"></v-text-field>
                      </v-col>
                      <v-col :key="`ss2` + i" cols="2">
                        <div v-if="additon.priced" class="d-flex">
                          <v-text-field outlined label="ERP Code" dense name="title" v-model="additon.erp_code">
                          </v-text-field>
                        </div>

                      </v-col>
                      <v-col cols="1">
                        <div class="d-flex justify-end">
                          <v-btn text color="error" @click="removeAdditons(i)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </v-col>
              <v-col cols="12">
                <v-switch label="متوفر ضمن اوقات معينة؟" v-model="inputs.data.timed"></v-switch>
              </v-col>
              <v-col cols="12" v-if="inputs.data.timed" md="6">
                <v-menu ref="menuFrom" :close-on-content-click="false" :nudge-right="40" :return-value.sync="from"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="from" label="من" prepend-icon="mdi-clock-time-four-outline" readonly outlined
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-model="from" full-width @click:minute="$refs.menuFrom.save(from)">
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" v-if="inputs.data.timed" md="6">
                <v-menu ref="menuTo" :close-on-content-click="false" :nudge-right="40" :return-value.sync="to"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="to" label="الى" prepend-icon="mdi-clock-time-four-outline" readonly outlined
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-model="to" full-width @click:minute="$refs.menuTo.save(to)">
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12">
                <v-file-input label="الصورة" prepend-icon="mdi-camera" v-model="file" clearable filled
                  placeholder="قم بإدخال الصورة" outlined accept="image/*"></v-file-input>
                <v-btn outlined v-if="inputs.data.photo != null || file != null" color="error" @click="() => {
                  inputs.data.photo = null;
                  file = null;
                }">
                  حذف الصورة القديمة
                </v-btn>
              </v-col>
              <v-col cols="12" md="12">
                <p class="mb-4">الوصف</p>
                <v-switch v-model="showDesc" label="يحتوي على وصف؟"></v-switch>
                <v-md-editor v-model="inputs.data.content" :text="text" v-if="showDesc" height="400px"></v-md-editor>
              </v-col>
              <v-col cols="12" md="12">
                <div class="d-flex justify-end">
                  <v-btn :disabled="!valid" large @click="add" color="primary">
                    <v-icon left>mdi-content-save</v-icon>
                    حفظ
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <div v-else class="d-flex justify-center mt-10 pt-10">
              <v-progress-circular indeterminate size="60"></v-progress-circular>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import moment from 'moment'
export default {
  data() {
    return {
      file: null,
      inputs: {
        data: {
          title: "",
          price: "",
          category: null,
          status: "enabled",
          content: "",
          currency: this.$store.state.resturant.currency,
          additons: [],
          design: null,
          resturant: this.$store.state.resturant.id,
          timed: false,
          from: null,
          to: null,
          discount: null,
          discount_type: null,
          erp_code: "",
        },
      },
      loading: false,
      showDesc: false,
      categories: [],
      designs: [],
      additons: [],
      discountTypes: [
        {
          text: 'نقصان',
          value: 'money'
        },
        {
          text: 'بالمئة',
          value: 'percentage'
        }
      ],
      to: null,
      from: null,
      valid: false,
      text: "",
      isEdit: false,
    };
  },
  methods: {
    addAdditons() {
      this.additons.push({
        text: "",
        erp_code: "",
        price: 0,
        priced: false,
      });
      this.additons.reverse();
    },
    removeAdditons(index) {
      this.additons.splice(index, 1);
    },
    async add() {
      this.$store.commit("setLoading", true);
      this.inputs.data.additons = this.additons;
      if (this.inputs.data.timed) {
        this.inputs.data.from = this.from + ":00.000";
        this.inputs.data.to = this.to + ":00.000";
      }
      if (this.file != null) {
        let res = await this.$service.uploadFile(this.file);
        this.inputs.data.photo = res.id;
      }
      if (this.$store.state.dialogType == "edit") {
        await this.$http.put(
          "/products/" + this.$store.state.item.id,
          this.inputs
        );
      } else {
        await this.$http.post("/products", this.inputs);
      }

      this.$store.commit("setLoading", false);
      this.$root.$emit("refresh");
      this.$store.state.dialog = false;
    },
  },
  async mounted() {
    // listen to the event
    let categories = await this.$http.get("/categories", {
      params: {
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
      },
      paramsSerializer: this.$service.qsParams,
    });
    this.categories = categories.data.data;
    let designs = await this.$http.get("/designs", {
      params: {
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
      },
      paramsSerializer: this.$service.qsParams,
    });
    this.designs = designs.data.data;
    this.$root.$on("fill-fields", (data) => {
      if (data.content == null) {
        data.content = "";
      }
      Object.keys(data).forEach((key) => {
        this.inputs.data[key] = data[key];
      });
      this.additons = data.additons;
      if (data.photo != null) {
        this.inputs.data.photo = data.photo.id;
      }
      if (data.timed) {
        this.inputs.data.timed = true;
        // get only HH:ss
        this.from = data.from.toString().substring(0, 5);
        this.to = data.to.toString().substring(0, 5);
        console.log(this.from);
      }
    });
  },
  watch: {
    "$store.state.dialog"(val) {
      if (val == false) {
        this.showDesc = false;
        this.inputs.data = {
          title: "",
          price: "",
          category: null,
          status: "",
          content: "",
          currency: "",
          discount: null,
          discount_type: null,
          design: null,
          resturant: this.$store.state.resturant.id,
        };
        this.additons = [];
        this.file = null;
      }
    },
  },
};
</script>
