<template>
  <div>
    <v-card :loading="$store.state.loading" elevation="0" class="transparent">
      <v-card-title>
        <div>
          <h3>المنتجات</h3>
          
        </div>
        <v-spacer></v-spacer>
        <v-btn class="" :outlined="viewType != 'table'"  @click="viewType = 'table'" color="primary">
          <v-icon>mdi-list-box-outline</v-icon>
        </v-btn>
        <v-btn class="mx-2" :outlined="viewType != 'card'"  @click="viewType = 'card'" color="primary">
          <v-icon>mdi-grid</v-icon>
        </v-btn>
        <v-btn color="primary" @click="openAddDialog()">اضافة منتج</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="options.filters.title.$containsi"
              label="بحث"
              single-line
              hide-details
              clearable
              solo
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-chip-group
              mandatory
              v-model="options.filters.category.id.$eq"
              active-class="success"
            >
              <v-chip
                v-for="category in categories"
                :key="category.id"
                color="primary"
                filter
                :value="category.id"
                label
                large
              >
                {{ category.title }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col  cols="12">
            <!-- <v-row> -->
            <draggable
            v-if="viewType == 'card'"
              class="row"
              v-model="products"
              @end="onDropCallback"
              :sort="true"
            >
              <v-col v-for="(item, i) in products" :key="i" cols="12" md="3">
                <v-card>
                  <v-img
                    style="position: relative;"
                    height="200"
                    contain
                    :src="
                      item.photo != null
                        ? $service.url + item.photo[0].url.replace('/uploads/','/uploads/w_200/')
                        : $service.url + $store.state.resturant.logo.url
                    "
                  >
                    <v-btn
                      @click="openEditDialog(item)"
                      fab
                      absolute
                      small
                      class="ma-3"
                      color="primary"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      @click="openDeleteItemDialog(item)"
                      outlined
                      fab
                      left
                      absolute
                      small
                      class="ma-3 mr-10"
                      color="error"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-img>
                  <v-card-title
                    class=" d-flex justify-center text-center font-weight-bold"
                  >
                    {{ item.title }}
                  </v-card-title>
                  <v-container>
                    <div class="d-flex justify-space-around align-center">
                      <div class="d-flex flex-column text-right align-end">
                        <span class="grey--text">السعر</span>
                        <b>{{ item.price }}</b>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="d-flex flex-column text-right align-end">
                        <span class="grey--text">المجموعة</span>
                        <b>{{ item.category.title }}</b>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="d-flex flex-column text-right align-end">
                        <span class="grey--text">ERP CODE</span>
                        <b>{{ item.erp_code }}</b>
                      </div>
                    </div>
                  </v-container>
                </v-card>
              </v-col>
            </draggable>
            <!-- </v-row> -->
            <v-data-table
            v-if="viewType == 'table'"
              :headers="headers"
              :ripple="false"
              :items="products"
              disable-pagination
              hide-default-footer
              :loading="$store.state.loading"
              no-data-text="لا يوجد"
              loading-text="جار التحميل..."
            >
              <template #body="props">
                <draggable
                  :list="props.items"
                  tag="tbody"
                  @end="onDropCallback"
                >
                  <DraggableTable
                    v-for="(item, index) in props.items"
                    :key="index"
                    :item="item"
                    :headers="headers"
                    item-class=""
                  >
                    <template v-slot:item.created="{ item }">
                      {{ $service.formatDate(new Date(item.createdAt), false) }}
                    </template>
                    <template v-slot:item.favorite="{ item }">
                      <v-switch
                        v-model="item.favorite"
                        color="primary"
                        @change="toggleFavorite(item)"
                      ></v-switch>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="item.status == 'enabled' ? 'success' : 'error'"
                        text-color="white"
                      >
                        {{ item.status == "enabled" ? "مفعل" : "غير مفعل" }}
                      </v-chip>
                    </template>
                    <template v-slot:item.photo="{ item }">
                      <span v-if="item.photo != null">
                        <v-btn
                          text
                          small
                          color="primary"
                          @click="
                            $service.openUrl($service.url + item.photo[0].url)
                          "
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <template v-slot:item.category.title="{ item }">
                      <div class="mt-4">
                        <v-autocomplete
                          :items="categories"
                          label="المجموعة"
                          v-model="item.category"
                          item-text="title"
                          dense
                          item-value="id"
                          @change="updateCategory(item)"
                          outlined
                        ></v-autocomplete>
                      </div>
                    </template>
                    <template v-slot:item.isActive="{ item }">
                      <v-btn
                        @click="toggleActive(item.id, item.isActive)"
                        :color="item.isActive ? 'success' : 'error'"
                      >
                        <v-icon>
                          {{ item.isActive ? "mdi-check" : "mdi-close" }}
                        </v-icon>
                        {{ item.isActive ? "متوفر" : "غير متوفر" }}
                      </v-btn>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div class="d-flex align-center">
                        <v-btn
                          color="primary"
                          @click="openEditDialog(item)"
                          outlined
                        >
                          <v-icon>mdi-pencil</v-icon>
                          تعديل
                        </v-btn>
                        <v-btn
                          color="error"
                          text
                          @click="deleteItem(item.id, item.title)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <template #item.design="{item}">
                      {{ item.design.title }}
                    </template>
                  </DraggableTable>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center">
              <v-pagination
                v-model="options.pagination.page"
                :length="parseInt(total / options.pagination.pageSize) + 1"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Add />
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          هل انت متأكد من حذف هذا العنصر؟
          <v-spacer></v-spacer>
          <v-btn icon color="error" text @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          سيتم حذف العنصر نهائياً وكل المعلومات المرتبطة به.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            الغاء
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItem(toDelete.id, toDelete.title)"
          >
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Add from "./Add.vue";
import DraggableTable from "../components/DraggableTable.vue";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
    Add,
    DraggableTable,
  },
  data() {
    return {
      products: [],
      dialog: false,
      viewType:'table',
      toDelete: null,
      total: 1,
      headers: [
        { text: "العنوان", value: "title" },
        { text: "الصورة", value: "photo" },
        { text: "التصميم", value: "design" },
        { text: "المجموعة", value: "category.title" },
        { text: "التوفر", value: "isActive", sortable: false },
        { text: "مفضل", value: "favorite" },
        { text: "الاجراءات", value: "actions" },
        { text: "الحالة", value: "status" },
      ],
      options: {
        sort: "index:asc",
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
          category: {
            id: {
              $eq: 1,
            },
          },
          title: {
            $containsi: "",
          },
        },
        populate: "*",
        pagination: {
          page: 1,
          pageSize: 50,
        },
      },
      categories: [],
    };
  },
  async created() {
    this.getnews();
    this.$root.$on("refresh", () => {
      this.getnews();
    });
    let categories = await this.$http.get("/categories", {
      params: {
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
        pagination: {
          page: 1,
          pageSize: 100,
        },
      },
      paramsSerializer: this.$service.qsParams,
    });
    this.categories = categories.data.data;
    this.options.filters.category.id.$eq = this.categories[0].id;
  },
  methods: {
    openDeleteItemDialog(item) {
      this.dialog = true;
      this.toDelete = item;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    onDropCallback(evt, originalEvent) {
      console.log("onDropCallback", evt, originalEvent);
      this.products = this.array_move(
        this.products,
        evt.oldIndex,
        evt.newIndex
      );
      // this.$forceUpdate();
      let ids = this.products.map((item, index) => {
        return { id: item.id, title: item.title, index: index };
      });

      this.updateIndex(ids);
      this.$root.$forceUpdate();
      this.$forceUpdate();
    },
    async toggleFavorite(item) {
      await this.$http.put("/products/" + item.id, {
        data: {
          favorite: item.favorite,
        },
      });
    },
    async toggleActive(id, isActive) {
      await this.$http.put("/products/" + id, {
        data: {
          isActive: !isActive,
        },
      });
      this.getnews();
    },
    async updateCategory(item) {
      if (item.category != null) {
        await this.$http.put("/products/" + item.id, {
          data: {
            category: item.category,
          },
        });
      }
    },
    getnews() {
      this.$store.commit("setLoading", true);
      this.$http
        .get("/products", {
          params: this.options,
          paramsSerializer: this.$service.qsParams,
        })
        .then((response) => {
          this.products = response.data.data;
          let update = false;
          this.products.forEach((element, i) => {
            if (element.index == null) {
              element.index = i;
              update = true;
            }
          });
          if (update) {
            let ids = this.products.map((item, index) => {
              return { id: item.id, title: item.title, index: index };
            });
            this.updateIndex(ids);
          }

          this.total = response.data.meta.pagination.total;
          this.$store.commit("setLoading", false);
        });
    },
    async updateIndex(products) {
      let data = {
        categoryId: this.options.filters.category.id.$eq,
        products,
      };
      // this.products.forEach((element) => {
      //   data.products.push({
      //     id: element.id,
      //     index: element.index,
      //   });
      // });
      await this.$http.post("/products/updateIndex", data);
    },
    openAddDialog() {
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "add";
    },
    openEditDialog(item) {
      this.$store.state.item = item;
      this.$root.$emit("fill-fields", item);
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "edit";
    },
    deleteItem(id, title) {
      title;
      this.$store.commit("setLoading", true);
      this.$http.put("/products/" + id, {
        data: {
          publishedAt: null,
        },
      });
      this.products = this.products.filter((item) => item.id != id);
      // this.getnews()
      this.$store.commit("setLoading", false);
    },
  },
  watch: {
    options: {
      handler() {
        this.getnews();
      },
      deep: true,
    },
  },
};
</script>
